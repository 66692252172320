import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getHUFDisplay, getNetFromGross } from "../../../../pricing/price";
import { useCandidate } from "../../../../redux/candidate";
import "./evaluation.css";

export const Evaluation = () => {
  const { evaluation } = useCandidate();
  return (
    <Container hidden={evaluation.length === 0} className="mt-3 mb-3">
      <Row>
        {evaluation.map(({ title, evalution }, i) => (
          <Col xs="3" key={i} className="mr-1 ml-1 candidate_evaluation__col">
            <Row className="justify-content-center">
              <Col xs="auto text-center">
                <h5>{title}</h5>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">{(evalution.percantage * 100.0).toFixed(1)} %</Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">Gross {getHUFDisplay(evalution.payment)}</Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                Net {getHUFDisplay(getNetFromGross(evalution.payment))}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
