import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ScreenHeader } from "./ScreenHeader";

export const WorkInProgress = ({ title, ...rest }) => (
  <Container {...rest} fluid className="mt-5">
    <ScreenHeader title={title} />
    <Row>
      <Col xs="auto">The classic under construction</Col>
    </Row>
  </Container>
);
