import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./singleRole.css";

export const SingleRole = ({ name, icon, description, active, ...rest }) => (
  <Container
    {...rest}
    fluid
    className={`roles__role_container ${active ? "active" : ""}`}
  >
    <Row className="justify-content-center pt-2">
      <Col xs="auto">
        <h3>{name}</h3>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="auto">
        {active ? (
          <span className="roles__role_active_text">ACTIVE</span>
        ) : (
          <span className="roles__role_inactive_text">INACTIVE</span>
        )}
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="12">
        <Image src={icon} width="100%" />
      </Col>
    </Row>
    <Row>
      <Col xs="auto">
        <p>{description}</p>
      </Col>
    </Row>
  </Container>
);
