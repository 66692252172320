import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "../redux/user";

export const OnlyPublicRoute = ({ component: Component, to, ...rest }) => {
  const { user } = useUser();
  const whereToSendUser = (props) => {
    const pathName = "/";
    const { location } = props.history;
    if (!location) {
      return pathName;
    }
    const { state: locationState } = location;
    if (!locationState) {
      return pathName;
    }
    const { from } = locationState;
    if (!from) {
      return pathName;
    }
    return from.pathname;
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: whereToSendUser(props),
                state: { from: props.location },
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
