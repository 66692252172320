import React from "react";
import { Accordion, Card, Col, Container, Image, Row } from "react-bootstrap";
import frontendEngineerIcon from "../../assets/frontend_engineer_icon.png";
import backendEngineerIcon from "../../assets/backend_engineer_icon.png";
import generalistIcon from "../../assets/generalist_icon.png";
import { login, useUser } from "../../redux/user";
import "./roles.css";
import { SingleRole } from "../common/SingleRole";
import { CAREER_DIRECTIONS, OVERLAY_TYPE } from "../../enums";
import { API } from "../../api";
import { setPathList } from "../../redux/paths";
import { toggleOverlay } from "../../redux/overlay";
import { SaveLoading } from "../common/SaveLoading";

export const Roles = () => {
  const { user, token } = useUser();
  console.log(user);
  const levels = [
    {
      name: "Entry level",
      body:
        "As your first steps in the IT industry, you learn the basics of how coding as a concept works in practice, what kind of processes a real engineering team use and how they manage to deliver projects. This level is constructed for colleagues entering our industry and therefore can be completed quickly.",
    },
    {
      name: "Medior level",
      body:
        "As a medior engineer you have already seen production grade software being made in practice and you are looking for further sharpening your skills and understanding about software development practices and processes. By the end of this level you are expected to proactively participate in designing software architecture and to embrace the art of creating high quality code.",
    },
    {
      name: "Senior level",
      body:
        "As a senior engineer you are confident in creating, handling and maintaining production grade softwares. You are actively sharing your knowledge and you know your responsibilities on your projects. By the end of this level you are capable to single handedly influence project outcomes.",
    },
    {
      name: "Executive level",
      body:
        "While the senior level does not have to be finished, we provide opportunities for stepping up into the executive layer. Roles in this level are specific and defined by context.",
    },
  ];
  const directions = [
    {
      name: "Frontend engineer",
      icon: frontendEngineerIcon,
      direction: CAREER_DIRECTIONS.FRONTEND,
      active: user.direction === CAREER_DIRECTIONS.FRONTEND,
      description:
        "Encouraged to deepen their knowledge and understanding about how the modern client side should be constructed in an optimal and efficient way",
    },
    {
      name: "Generalist",
      icon: generalistIcon,
      direction: CAREER_DIRECTIONS.GENERALIST,
      active: user.direction === CAREER_DIRECTIONS.GENERALIST,
      description:
        "Encouraged to understand how an efficient product lifecycle is designed and how different IT solutions can help in different situations",
    },
    {
      name: "Backend engineer",
      icon: backendEngineerIcon,
      direction: CAREER_DIRECTIONS.BACKEND,
      active: user.direction === CAREER_DIRECTIONS.BACKEND,
      description:
        "Encouraged to deepen their knowledge and understanding about how server applications are designed and scaled in a modern cloud environment",
    },
  ];
  const selectDirection = (direction) => {
    toggleOverlay(OVERLAY_TYPE.API_SAVING);
    API.changeDirection(token, direction).then(({ user, token }) => {
      localStorage.setItem("token", token);
      login({ token, user });
      setPathList({ completed: user.completed });
      toggleOverlay();
    });
  };
  return (
    <>
      <SaveLoading />
      <Container className="mb-5">
        <Row className="justify-content-center mt-2 mb-4">
          <Col xs="auto">
            <span className="roles__intro_text">
              Our value as a company lies behind personal growth and
              professional development
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <p>
              Your future as an engineer inside our company structure is defined
              on 4 levels:
            </p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs="12">
            <Accordion>
              {levels.map(({ name, body }, i) => (
                <Card key={i}>
                  <Card.Header className="roles__card_header">
                    <Accordion.Toggle
                      as={Card.Header}
                      variant="link"
                      eventKey={`${i}`}
                    >
                      {name}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`${i}`}>
                    <Card.Body>{body}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
        </Row>
        <Row className="mt-3 mb-2">
          <Col xs="12">
            <p>
              As a developer your growth can happen in multiple ways. While as a
              company we try to stay flexible around each persons personal
              priorities, our structure currently supports 3 ways of
              professional development throughut your way towards executive
              level.
            </p>
          </Col>
        </Row>
        <Row>
          {directions.map((options, i) => (
            <Col xs="4" key={i}>
              <SingleRole
                {...options}
                onClick={() => selectDirection(options.direction)}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};
