import { useState, useLayoutEffect } from "react";
import { store } from "./store";

const storeKey = "candidate";
const initialState = {
  direction: "",
  completed: [],
  rejected: [],
  evaluation: [],
  information: {
    name: "",
    email: "",
  },
};

const reducers = {
  setCandidateDirection: (state, { direction = "" }) => ({
    ...state,
    direction,
  }),
  setCandidateInformation: (
    state,
    { name = state.information.name, email = state.information.email }
  ) => {
    return {
      ...state,
      information: {
        ...state.information,
        name,
        email,
      },
    };
  },
  setCandidateEvaluation: (state, { evaluation = state.evaluation }) => ({
    ...state,
    evaluation,
  }),
  setCompletedLevelItem: (state, { level }) => {
    const index = state.completed.findIndex(({ _id }) => _id === level._id);
    if (index < 0) {
      return { ...state, completed: [...state.completed, level] };
    }
    return {
      ...state,
      completed: state.completed.map((item, i) => (i === index ? level : item)),
    };
  },
  setRejectedLevelItem: (state, { level }) => {
    const index = state.rejected.findIndex(({ _id }) => _id === level._id);
    if (index < 0) {
      return { ...state, rejected: [...state.rejected, level] };
    }
    return {
      ...state,
      rejected: state.rejected.map((item, i) => (i === index ? level : item)),
    };
  },
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const useCandidate = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const setCandidateDirection = (direction) =>
  store.dispatch({ type: "setCandidateDirection", payload: { direction } });
export const setCompletedLevelItem = (level) =>
  store.dispatch({ type: "setCompletedLevelItem", payload: { level } });
export const setRejectedLevelItem = (level) =>
  store.dispatch({ type: "setRejectedLevelItem", payload: { level } });
export const setCandidateInformation = (name, email) =>
  store.dispatch({ type: "setCandidateInformation", payload: { name, email } });
export const setCandidateEvaluation = (evaluation) =>
  store.dispatch({ type: "setCandidateEvaluation", payload: { evaluation } });

// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
