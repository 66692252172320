import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toggleOpenLevel, usePaths } from "../../../../redux/paths";
import { CollapsedLevel } from "./CollapsedLevel";
import { NewRole } from "./NewRole";
import { Role } from "./components/Role";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./level.css";

export const Level = ({ levelKey = "0", ...rest }) => {
  const { levelGrouped, display, edit } = usePaths();
  const level = Number.parseInt(levelKey);
  const collapsed = display.levels.opened.findIndex((l) => l === level) < 0;
  return (
    <Container key={levelKey} {...rest} fluid className="pt-4 pb-4">
      <CollapsedLevel level={level} />
      <Row
        className={`justify-content-around path__level path__level_${level}`}
        hidden={collapsed}
      >
        {levelGrouped[level].map((_, i) => (
          <Role key={i} level={level} index={i} />
        ))}
        {levelGrouped[level].length >= 1 &&
        levelGrouped[level][0].title !== "New level element" &&
        edit.showOption ? (
          <NewRole level={level} />
        ) : null}
        <Container
          fluid
          className="mt-2 path_level__opener_container"
          onClick={() => toggleOpenLevel(level)}
        >
          <Row className="justify-content-center">
            <Col xs="auto" style={{ fontSize: "2em" }}>
              <FontAwesomeIcon icon={faChevronUp} />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
