import React, { useLayoutEffect } from "react";
import { Container } from "react-bootstrap";
import { Router, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { OnlyPublicRoute } from "./OnlyPublicRoute";
import { Paths } from "../components/Path";
import { Login } from "../components/Login";
import { useUser, login } from "../redux/user";
import { history } from "./history";
import { API } from "../api";
import "./Routing.css";
import { setPathList } from "../redux/paths";
import { Navigation } from "../components/Navigation/Navigation";
import { Users } from "../components/User/User";
import { Home } from "../components/Home/Home";
import { Settings } from "../components/Settings/Settings";
import { Roles } from "../components/Roles/Roles";
import { Candidate } from "../components/Candidate/Candidate";
import { SavedEvaluations } from "../components/Evaluations/SavedEvaluations";

async function preFetchUser() {
  const internalToken = localStorage.getItem("token");
  if (!internalToken) {
    return;
  }
  try {
    const response = await API.me(internalToken);
    return { user: response.data, token: internalToken };
  } catch (e) {
    console.log("error in routing ", e);
    return;
  }
}

export const Routing = () => {
  const logout = () => console.log("logout");
  const { user, token } = useUser();
  useLayoutEffect(() => {
    if (user && token) {
      return;
    }
    let isPresent = user && token;
    preFetchUser().then((userInfo) => {
      if (!userInfo) {
        return;
      }
      if (!isPresent) {
        login(userInfo);
        setPathList({ completed: userInfo.user.completed });
      }
    });
  }, []);
  return (
    <Router history={history}>
      <Container fluid>
        <Navigation />
        <Switch>
          <OnlyPublicRoute key="/login" exact path="/login" component={Login} />
          <PrivateRoute key="/" exact path="/" component={Home} />
          <PrivateRoute
            key="/settings"
            exact
            path="/settings"
            component={Settings}
          />
          <PrivateRoute key="/roles" exact path="/roles" component={Roles} />
          <PrivateRoute key="/path" exact path="/path" component={Paths} />
          <PrivateRoute key="/users" exact path="/users" component={Users} />
          <PrivateRoute
            key="/candidate"
            exact
            path="/candidate"
            component={Candidate}
          />
          <PrivateRoute
            key="/evaluations"
            exact
            path="/evaluations"
            component={SavedEvaluations}
          />
        </Switch>
      </Container>
    </Router>
  );
};
