import { useState, useLayoutEffect } from "react";
import _ from "lodash";
import { store } from "./store";

const storeKey = "paths";
const initialState = {
  available: [],
  completed: [],
  loaded: false,
  edit: {
    showOption: false,
    showCreate: false,
    createLevel: -1,
    index: -1,
  },
  display: {
    levels: {
      opened: [],
    },
  },
  levelGrouped: {},
};

const reducers = {
  setPathList: (
    state,
    { available = state.available, completed = state.completed }
  ) => ({
    ...state,
    available,
    completed,
    levelGrouped: _.groupBy(available, (a) => a.level),
    loaded: true,
  }),
  addNewLevel: (state) => {
    const maxLevel = state.available.reduce(
      (max, { level }) => (level > max ? level : max),
      0
    );
    const availableUpdate = [
      ...state.available,
      {
        level: maxLevel + 1,
        title: "New level element",
        categories: [],
        priceRange: { min: 0, max: 0 },
      },
    ];
    return {
      ...state,
      available: availableUpdate,
      levelGrouped: _.groupBy(availableUpdate, (a) => a.level),
    };
  },
  upsertById: (state, { _id, title, categories, level, priceRange, directions = [] }) => {
    const index = state.available.findIndex(({ _id: i }) => i === _id);
    let available = [
      ...state.available,
      { _id, title, categories, level, priceRange, directions },
    ];
    if (index >= 0) {
      available = state.available.map((item, i) =>
        i === index ? { _id, title, categories, level, priceRange, directions } : item
      );
    }
    return {
      ...state,
      available,
      levelGrouped: _.groupBy(available, (a) => a.level),
    };
  },
  toggleEdit: (state) => ({
    ...state,
    edit: { ...state.edit, showOption: !state.edit.showOption },
  }),
  toggleCreate: (state, { level = state.edit.createLevel }) => ({
    ...state,
    edit: {
      ...state.edit,
      showCreate: !state.edit.showCreate,
      createLevel: level,
    },
  }),
  toggleOpenLevel: (state, { level }) => {
    const index = state.display.levels.opened.findIndex((l) => l === level);
    if (index < 0) {
      return {
        ...state,
        display: {
          ...state.display,
          levels: {
            ...state.display.levels,
            opened: [...state.display.levels.opened, level],
          },
        },
      };
    }
    const opened = [...state.display.levels.opened];
    opened.splice(index, 1);
    return {
      ...state,
      display: {
        ...state.display,
        levels: {
          ...state.display.levels,
          opened,
        },
      },
    };
  },
  setShownIndex: (state, { index }) => ({
    ...state,
    edit: { ...state.edit, index },
  }),
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const usePaths = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const setPathList = ({ available, completed }) =>
  store.dispatch({ type: "setPathList", payload: { available, completed } });
export const addNewLevel = () =>
  store.dispatch({ type: "addNewLevel", payload: {} });
export const toggleEdit = () =>
  store.dispatch({ type: "toggleEdit", payload: {} });
export const toggleOpenLevel = (level) =>
  store.dispatch({ type: "toggleOpenLevel", payload: { level } });
export const toggleCreate = (level) =>
  store.dispatch({ type: "toggleCreate", payload: { level } });
export const setShownIndex = (index) =>
  store.dispatch({ type: "setShownIndex", payload: { index } });
export const upsertById = (_id, title, level, categories, priceRange, directions ) =>
  store.dispatch({
    type: "upsertById",
    payload: { _id, title, level, categories, priceRange, directions },
  });

// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
