import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { OVERLAY_TYPE } from "../../enums";
import { useOverlay } from "../../redux/overlay";
import "./saveLoading.css";

export const SpinButtonLoading = ({ text, ...rest }) => {
  const { active, type } = useOverlay();
  return (
    <Button {...rest}>
      {active && type === OVERLAY_TYPE.API_SAVING ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="fa-spin"
        />
      ) : (
        text
      )}
    </Button>
  );
};
