import React, { useLayoutEffect } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { API } from "../../../../api";
import { OVERLAY_TYPE } from "../../../../enums";
import { toggleOverlay } from "../../../../redux/overlay";
import { useUser } from "../../../../redux/user";
import {
  toggleUserOpen,
  updateUserInList,
  useUsers,
} from "../../../../redux/users";
import "./userRow.css";
import { RoleEvaluation } from "./components/RoleEvaluation/RoleEvaluation";

export const UserRow = ({ user, evaluation, ...rest }) => {
  const { display } = useUsers();
  const { token } = useUser();
  const isClosed = display.usersShown.findIndex((id) => id === user._id) < 0;
  const { picture, email, given_name, family_name, role, completed } = user;
  const fullName = `${given_name} ${family_name}`;
  let highestCompleted = completed.reduce(
    (max, completedRole) => {
      if (max.level < completedRole.level) return completedRole;
      return max;
    },
    { level: -1 }
  );
  const setAdmin = (userId) => {
    toggleOverlay(OVERLAY_TYPE.API_SAVING);
    API.addAdmin(token, userId).then(({ user: receivedUser }) => {
      updateUserInList(receivedUser);
      toggleOverlay();
    });
  };
  console.log(evaluation);
  if (highestCompleted.level === -1) highestCompleted.title = "New comer";
  return (
    <Col
      xs={isClosed ? "3" : "12"}
      className={isClosed ? "mr-1" : ""}
      {...rest}
    >
      <Card style={{ width: "100%", height: "100%" }} hidden={!isClosed}>
        <Card.Img variant="top" src={picture} />
        <Card.Body>
          <Card.Title>{fullName}</Card.Title>
          <Container fluid className="mb-2">
            <Row>
              <Col xs="auto">{highestCompleted.title}</Col>
            </Row>
            <Row>
              <Col xs="auto">{email}</Col>
            </Row>
          </Container>
          <Button
            variant="primary"
            onClick={() => toggleUserOpen(user._id)}
            hidden={highestCompleted.level === -1}
          >
            Open user card
          </Button>
          <Button
            variant="secondary"
            onClick={() => setAdmin(user._id)}
            hidden={role === "admin"}
            className="ml-2"
          >
            Make admin
          </Button>
        </Card.Body>
      </Card>
      <Container
        hidden={isClosed}
        className="users__user_expand_container mb-2 mt-2"
      >
        <Row className="justify-content-center">
          <Col xs="3">
            <Image src={picture} />
          </Col>
          <Col xs="auto">
            <Row>
              <Col xs="auto">
                <h3>{fullName}</h3>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <p>{email}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 justify-content-center">
          <Col xs="auto">
            <h2>Career progress</h2>
          </Col>
        </Row>
        <Row className="justify-content-between">
          {evaluation
            .sort((a, b) => a.level - b.level)
            .map(({ _id, level, title, evalution: innerEvaluation }, i) => (
              <RoleEvaluation
                {...{
                  _id,
                  userId: user._id,
                  title,
                  grossPayment: innerEvaluation.payment,
                  completedPercentage: innerEvaluation.percantage,
                  key: i,
                }}
              />
            ))}
        </Row>
        <Row className="justify-content-center mt-2">
          <Col xs="auto">
            <Button
              variant="primary"
              onClick={() => toggleUserOpen(user._id)}
            >
              Close user card
            </Button>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
