import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CAREER_DIRECTIONS } from "../../../../../enums";
import "./pathDirection.css";

export const PathDirection = ({
  toggleDirection = () => {},
  directions = [],
  ...rest
}) => {
  return (
    <Container fluid {...rest}>
      <Row>
        {Object.keys(CAREER_DIRECTIONS).map((key) => (
          <Col
            xs="auto"
            key={key}
            className={`mr-1 path__direction_tag ${
              directions.findIndex((v) => v === CAREER_DIRECTIONS[key]) >= 0
                ? "active"
                : ""
            }`}
            onClick={() => toggleDirection(CAREER_DIRECTIONS[key])}
          >
            {key.toUpperCase()}
          </Col>
        ))}
      </Row>
    </Container>
  );
};
