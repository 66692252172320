import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../../../../api";
import { OVERLAY_TYPE } from "../../../../../enums";
import { toggleOverlay } from "../../../../../redux/overlay";
import {
  setPathList,
  setShownIndex,
  usePaths,
} from "../../../../../redux/paths";
import { login, useUser } from "../../../../../redux/user";
import { Category } from "./Category";
import { PriceRange } from "./PriceRange";

export const Role = ({ level, index, ...rest }) => {
  const { user, token } = useUser();
  const { available, edit, levelGrouped, completed } = usePaths();
  const { title, categories, _id, directions } = levelGrouped[level][index];
  const isCompleted = (_id, dim) =>
    completed.reduce((found, { _id: pathID, categories }) => {
      if (pathID !== _id) return found;
      return categories.reduce(
        (subDimFound, { subdimensions }) =>
          subdimensions.reduce((l, subDim) => l || subDim === dim, subDimFound),
        found
      );
    }, false);
  const complete = (_id, nameOfCategory, dim) => {
    const pathIndex = completed.findIndex(({ _id: id }) => _id === id);
    let updatedList = completed;
    // if completed does not have that role yet, add it
    if (pathIndex < 0) {
      const indexInAvailableList = available.findIndex(
        ({ _id: id }) => _id === id
      );
      // If not in the available list, throw an error cause that should not be possible
      if (indexInAvailableList < 0) {
        throw new Error("Inconsistent available and completed list");
      }
      updatedList.push({
        ...available[indexInAvailableList],
        // Only add 1 category and 1 subdimension (which is clicked)
        categories: available[indexInAvailableList].categories.map((categ) => ({
          ...categ,
          subdimensions: categ.subdimensions.reduce((completedList, subDim) => {
            if (subDim === dim) completedList.push(dim);
            return completedList;
          }, []),
        })),
      });
    } else {
      // Otherwise just find the correct category and add subdimension
      updatedList = completed.map(({ _id: id, categories, ...rest }) => {
        if (_id === id) {
          categories = categories.map((categ) => {
            if (categ.name === nameOfCategory) categ.subdimensions.push(dim);
            return categ;
          });
        }
        return { _id: id, categories, ...rest };
      });
    }
    toggleOverlay(OVERLAY_TYPE.API_SAVING);
    API.updateCompleted(token, updatedList).then(({ token, user }) => {
      localStorage.setItem("token", token);
      login({ token, user });
      setPathList({ completed: updatedList });
      toggleOverlay();
    });
  };
  const isPartOfActiveDirection =
    directions.findIndex((v) => v === user.direction) >= 0;
  return (
    <Col xs="4" {...rest} hidden={!isPartOfActiveDirection && !edit.showOption}>
      <Row className="justify-content-center">
        <Col xs="auto">
          <h3 className="path__title">{title}</h3>
        </Col>
        <Col
          xs="auto"
          className="path__editor"
          hidden={!edit.showOption}
          onClick={() =>
            setShownIndex(available.findIndex((v) => v.title === title))
          }
        >
          <FontAwesomeIcon icon={faPenSquare} />
        </Col>
      </Row>
      <PriceRange level={level} index={index} />
      <Row>
        <Col xs="12">
          {categories.map(({ name, subdimensions }) => (
            <Category
              _id={_id}
              key={name}
              name={name}
              subdimensions={subdimensions}
              isCompleted={isCompleted}
              complete={complete}
            />
          ))}
        </Col>
      </Row>
    </Col>
  );
};
