import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  setCompletedLevelItem,
  setRejectedLevelItem,
  useCandidate,
} from "../../../redux/candidate";
import "./candidateRole.css";

export const CandidateRole = ({
  _id,
  title,
  categories,
  priceRange,
  opened,
  ...rest
}) => {
  const { completed, rejected } = useCandidate();
  const findDimInSet = (set, name, dim) => {
    const foundLevel = set.find(({ _id: id }) => _id === id);
    if (!foundLevel) return false;
    const foundCateg = foundLevel.categories.find(({ name: n }) => n === name);
    if (!foundCateg) return false;
    return foundCateg.subdimensions.findIndex((d) => d === dim) > -1;
  };
  const findIfRejectedDim = (name, dim) => findDimInSet(rejected, name, dim);
  const findIfCompletedDim = (name, dim) => findDimInSet(completed, name, dim);
  const addToAndGetLevel = (set, name, dim) => {
    let foundLevel = set.find(({ _id: id }) => _id === id);
    if (!foundLevel) {
      foundLevel = {
        _id,
        title,
        categories: [],
        priceRange,
      };
    }
    let foundCateg = foundLevel.categories.find(({ name: n }) => n === name);
    if (!foundCateg) {
      foundCateg = {
        name,
        subdimensions: [],
      };
      foundLevel.categories.push(foundCateg);
    }
    let foundDim = foundCateg.subdimensions.find((d) => d === dim);
    if (!foundDim) {
      foundCateg.subdimensions.push(dim);
    }
    return foundLevel;
  };
  const addToCompleted = (name, dim) => {
    const levelItem = addToAndGetLevel(completed, name, dim);
    setCompletedLevelItem(levelItem);
  };
  const addToRejected = (name, dim) => {
    const levelItem = addToAndGetLevel(rejected, name, dim);
    setRejectedLevelItem(levelItem);
  };
  return (
    <Container fluid {...rest}>
      <Row className="justify-content-center">
        <Col xs="auto">
          <h4>{title}</h4>
        </Col>
      </Row>
      {categories.map(({ name, subdimensions }, i) => {
        return (
          <Container fluid key={i} hidden={!opened} className="pb-3">
            <Row key={i} className="achievements_display__title_row">
              <Col xs="12">
                <span>{name}</span>
              </Col>
            </Row>
            {subdimensions.map((dim, y) => (
              <Row
                key={y}
                className={`achievements_display__subdimension_row ${
                  findIfCompletedDim(name, dim)
                    ? "active"
                    : findIfRejectedDim(name, dim)
                    ? "rejected"
                    : ""
                }`}
              >
                <Col xs="12">
                  <Row className="justify-content-between">
                    <Col
                      xs="2"
                      className="candidate_role__thumbs_col up"
                      onClick={() => addToCompleted(name, dim)}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </Col>
                    <Col xs="8">{dim}</Col>
                    <Col
                      xs="2"
                      className="candidate_role__thumbs_col down"
                      onClick={() => addToRejected(name, dim)}
                    >
                      <FontAwesomeIcon icon={faThumbsDown} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Container>
        );
      })}
    </Container>
  );
};
