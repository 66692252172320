import React from "react";
import { Modal } from "react-bootstrap";
import { toggleCreate, upsertById, usePaths } from "../../../redux/paths";
import { useUser } from "../../../redux/user";
import { API } from "../../../api";
import { PathModal } from "./PathModal/PathModal";

export const CreateModal = () => {
  const { edit } = usePaths();
  const { token } = useUser();
  const show = edit.showCreate;
  const addNewLevelItem = (
    _,
    innerTitle,
    level,
    innerCategories,
    innerPriceRange,
    directions
  ) => {
    const newItem = {
      level,
      categories: innerCategories,
      title: innerTitle,
      priceRange: innerPriceRange,
      directions,
    };
    API.upsertLevelItem(token, newItem).then((savedItem) => {
      upsertById(
        savedItem._id,
        savedItem.title,
        savedItem.level,
        savedItem.categories,
        savedItem.directions,
      );
      toggleCreate();
    });
  };
  const options = {
    level: edit.createLevel,
    title: "New role",
    categories: [],
    priceRange: {},
    onSave: addNewLevelItem,
    onClose: toggleCreate,
  };
  return (
    <Modal show={show} onHide={toggleCreate} dialogClassName="modal-90w">
      {show ? <PathModal {...options} /> : null}
    </Modal>
  );
};
