import React, { useLayoutEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { API } from "../../api";
import { OVERLAY_TYPE } from "../../enums";
import { getHUFDisplay, getNetFromGross } from "../../pricing/price";
import { setEvaluationsList, useEvaluations } from "../../redux/evaluations";
import { toggleOverlay } from "../../redux/overlay";
import { useUser } from "../../redux/user";
import { SpinLoading } from "../common/SpinLoading";
import "./savedEvaluations.css";

export const SavedEvaluations = () => {
  const { token } = useUser();
  const { list, loaded } = useEvaluations();
  useLayoutEffect(() => {
    if (loaded) return;
    toggleOverlay(OVERLAY_TYPE.API_LOADING);
    API.fetchEvaluations(token)
      .then(({ evaluations }) => setEvaluationsList(evaluations))
      .catch(console.log)
      .finally(() => toggleOverlay());
  });
  return (
    <Container>
      <SpinLoading />
      {list.map(({ information, direction, evaluation: evaluations }, i) => (
        <Card key={i}>
          <Card.Header>
            <Card.Title>{information.name}</Card.Title>
            {information.email} - {direction}
          </Card.Header>
          <Card.Body>
            <Row>
              {evaluations.map(({ title, evalution }, i) => {
                return (
                  <Col xs="3" key={i}>
                    <Card>
                      <Card.Header>
                        <Card.Title className="saved_evaluations__role_card_title">{title}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="auto">
                            {(evalution.percantage * 100.0).toFixed(1)} %
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="auto">
                            Gross {getHUFDisplay(evalution.payment)}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="auto">
                            Net{" "}
                            {getHUFDisplay(getNetFromGross(evalution.payment))}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};
