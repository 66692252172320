import axios from "axios";
import { MockAPI } from "./mock";

class APIHandler {
  constructor() {
    this.url =
      process.env.API_URL ||
      "https://ks1a3mbv8g.execute-api.us-east-1.amazonaws.com/dev";
    this.useMock = process.env.MOCK_API === "true";
    if (this.useMock) {
      this.mock = new MockAPI();
    }
  }
  createHeaders(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  async me(token) {
    const response = await axios.get(
      `${this.url}/me`,
      this.createHeaders(token)
    );
    return response;
  }

  async login(token) {
    const response = await axios.post(`${this.url}/login`, { token });
    return response;
  }

  async fetchPath(token) {
    const response = await axios.get(
      `${this.url}/path`,
      this.createHeaders(token)
    );
    return { available: response.data };
    if (this.useMock) return this.mock.fetchPath();
    // TODO: Add API logic here
    return this.mock.fetchPath();
  }
  async updateCompleted(token, completed) {
    const response = await axios.post(
      `${this.url}/path/completed`,
      { completed },
      this.createHeaders(token)
    );
    return response.data;
  }
  async upsertLevelItem(token, levelItem) {
    const response = await axios.post(
      `${this.url}/path`,
      levelItem,
      this.createHeaders(token)
    );
    return response.data;
    if (this.useMock) return levelItem;
    // TODO: Add API logic here
    return true;
  }
  async changeDirection(token, direction) {
    const response = await axios.post(
      `${this.url}/users/direction`,
      { direction },
      this.createHeaders(token)
    );
    return response.data;
  }
  async fetchUsers(token) {
    const response = await axios.get(
      `${this.url}/users`,
      this.createHeaders(token)
    );
    return response.data;
  }
  async addAdmin(token, userId) {
    const response = await axios.post(
      `${this.url}/users/admin`,
      { userId },
      this.createHeaders(token)
    );
    return response.data;
  }
  async getEvaluation(token, completed) {
    const response = await axios.post(
      `${this.url}/candidate/evaluate`,
      { completed },
      this.createHeaders(token)
    );
    return response.data;
  }
  async saveEvaluation(token, evaluation) {
    const response = await axios.post(
      `${this.url}/candidate/evaluation`,
      evaluation,
      this.createHeaders(token)
    );
    return response.data;
  }
  async fetchEvaluations(token) {
    const response = await axios.get(
      `${this.url}/candidate/evaluation`,
      this.createHeaders(token)
    );
    return response.data;
  }
}

export const API = new APIHandler();
