const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "HUF",
});
export const getHUFDisplay = (number = 0) => numberFormatter.format(number);

export const getNetFromGross = (grossPayment) => {
  const SZJA = grossPayment * 0.15;
  const TEB = grossPayment * 0.04;
  const PEB = grossPayment * 0.03;
  const RETIREMENT = grossPayment * 0.1;
  const LABOUR_MARKET = grossPayment * 0.015;
  return grossPayment - (SZJA + TEB + PEB + RETIREMENT + LABOUR_MARKET);
};
