import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Box } from "./components/Box";
import "./home.css";
import careerIcon from "../../assets/career_icon.png";
import userSettingsIcon from "../../assets/user_settings_icon.png";
import rolesIcon from "../../assets/roles_icon.png";

export const Home = () => {
  const history = useHistory();
  const takeUserTo = (to) => history.push(to);
  const boxes = [
    {
      subpath: "/roles",
      name: "Roles inside the company",
      icon: rolesIcon,
    },
    {
      subpath: "/path",
      name: "Personal career",
      icon: careerIcon,
    },
    {
      subpath: "/settings",
      name: "User settings",
      icon: userSettingsIcon,
    },
  ];
  return (
    <Container className="mt-5">
      <Row>
        {boxes.map((options, i) => (
          <Col
            key={i}
            xs="4"
            className="home__box_col"
            onClick={() => takeUserTo(options.subpath)}
          >
            <Box {...options} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
