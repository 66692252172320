import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { usePaths } from "../../../../../../../../redux/paths";
import { useUsers } from "../../../../../../../../redux/users";
import "./achievementsDisplay.css";

export const AchievementsDisplay = ({ _id, userId, ...rest }) => {
  const { list: users } = useUsers();
  const { available, loaded } = usePaths();
  if (!loaded || !_id || available.length === 0)
    return (
      <Container fluid {...rest}>
        <Row>
          <Col xs="12">Loading achievement display</Col>
        </Row>
      </Container>
    );
  const { user } = users.find(({ user }) => user._id === userId);
  const completedRole = user.completed.find(({ _id: id }) => id === _id);
  const availableRole = available.find(({ _id: id }) => id === _id);
  const findIfCompletedDim = (name, dim) => {
    const category = completedRole.categories.find(({ name: n }) => n === name);
    if (!category) return false;
    const dimension = category.subdimensions.find((d) => d === dim);
    return !!dimension;
  };
  return (
    <Container fluid {...rest}>
      {availableRole.categories.map(({ name, subdimensions }, i) => {
        return (
          <Container fluid key={i}>
            <Row key={i} className="achievements_display__title_row">
              <Col xs="12">
                <span>{name}</span>
              </Col>
            </Row>
            {subdimensions.map((dim, y) => (
              <Row
                key={y}
                className={`achievements_display__subdimension_row ${
                  findIfCompletedDim(name, dim) ? "active" : ""
                }`}
              >
                <Col xs="12">{dim}</Col>
              </Row>
            ))}
          </Container>
        );
      })}
    </Container>
  );
};
