import React, { useLayoutEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { API } from "../../api";
import { CAREER_DIRECTIONS, OVERLAY_TYPE } from "../../enums";
import { toggleOverlay } from "../../redux/overlay";
import { useCandidate, setCandidateDirection } from "../../redux/candidate";
import { setPathList, usePaths } from "../../redux/paths";
import { useUser } from "../../redux/user";
import { SpinLoading } from "../common/SpinLoading";
import { SingleRole } from "../common/SingleRole";
import frontendEngineerIcon from "../../assets/frontend_engineer_icon.png";
import backendEngineerIcon from "../../assets/backend_engineer_icon.png";
import generalistIcon from "../../assets/generalist_icon.png";
import { SmartCollapsingRow } from "./components/SmartCollapsingRow";
import { CandidateRole } from "./components/CandidateRole";
import { CandidateInformation } from "./components/CandidateInformation";
import { EvaluateButtonRow } from "./components/Evaluation/EvaluateButtonRow";
import { Evaluation } from "./components/Evaluation/Evaluation";

export const Candidate = () => {
  const { direction: currentDirection } = useCandidate();
  const { token } = useUser();
  const { available, loaded } = usePaths();
  useLayoutEffect(() => {
    if (loaded && available.length !== 0) return;
    toggleOverlay(OVERLAY_TYPE.API_LOADING);
    API.fetchPath(token).then((paths) => {
      setPathList(paths);
      toggleOverlay();
    });
  });
  const directions = [
    {
      name: "Frontend engineer",
      icon: frontendEngineerIcon,
      direction: CAREER_DIRECTIONS.FRONTEND,
      active: currentDirection === CAREER_DIRECTIONS.FRONTEND,
      description:
        "Encouraged to deepen their knowledge and understanding about how the modern client side should be constructed in an optimal and efficient way",
    },
    {
      name: "Generalist",
      icon: generalistIcon,
      direction: CAREER_DIRECTIONS.GENERALIST,
      active: currentDirection === CAREER_DIRECTIONS.GENERALIST,
      description:
        "Encouraged to understand how an efficient product lifecycle is designed and how different IT solutions can help in different situations",
    },
    {
      name: "Backend engineer",
      icon: backendEngineerIcon,
      direction: CAREER_DIRECTIONS.BACKEND,
      active: currentDirection === CAREER_DIRECTIONS.BACKEND,
      description:
        "Encouraged to deepen their knowledge and understanding about how server applications are designed and scaled in a modern cloud environment",
    },
  ];
  console.log(available, loaded);
  const filteredAvailable =
    currentDirection === ""
      ? available
      : available.filter(
          ({ directions }) =>
            directions.findIndex((dir) => dir === currentDirection) > -1
        );
  return (
    <Container fluid>
      <SpinLoading />
      <CandidateInformation />
      <Container className="mt-3">
        <Row>
          {directions.map((options, i) => (
            <Col
              xs="4"
              key={i}
              onClick={() => setCandidateDirection(options.direction)}
            >
              <SingleRole {...options} />
            </Col>
          ))}
        </Row>
      </Container>
      <Container hidden={!loaded} className="mt-4">
        <SmartCollapsingRow className="mb-3">
          {filteredAvailable.map((role, i) => (
            <CandidateRole key={i} {...role} />
          ))}
        </SmartCollapsingRow>
        <EvaluateButtonRow />
      </Container>
      <Evaluation />
    </Container>
  );
};
