import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getHUFDisplay } from "../../../../../../../pricing/price";

export const EvaluationDisplay = ({
  completedPercentage = 0,
  grossPayment = 0,
  paymentInHand = 0,
  ...rest
}) => (
  <Container fluid {...rest}>
    <Row>
      <Col xs="12">{(completedPercentage * 100.0).toFixed(2)} %</Col>
    </Row>
    <Row>
      <Col xs="12">Expected payment: {getHUFDisplay(grossPayment)}</Col>
    </Row>
    <Row>
      <Col xs="12">Payment in hand: {getHUFDisplay(paymentInHand)}</Col>
    </Row>
  </Container>
);
