import React from "react";
import { Modal } from "react-bootstrap";
import { API } from "../../../api";
import { setShownIndex, upsertById, usePaths } from "../../../redux/paths";
import { useUser } from "../../../redux/user";
import { PathModal } from "./PathModal/PathModal";

export const EditModal = () => {
  const { edit, available } = usePaths();
  const { token } = useUser();
  const show = edit.index > -1;
  const handleClose = () => setShownIndex(-1);
  const upsertByIdAndClose = (_id, title, level, categories, priceRange, directions) => {
    API.upsertLevelItem(token, {
      _id,
      title,
      level,
      categories,
      priceRange,
      directions,
    }).then(() => {
      upsertById(_id, title, level, categories, priceRange, directions);
      handleClose();
    });
  };
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
      {show ? (
        <PathModal
          {...{
            ...available[edit.index],
            onClose: handleClose,
            onSave: upsertByIdAndClose,
          }}
        />
      ) : null}
    </Modal>
  );
};
