export const EDIT_MODEL_TYPE = {
  UPDATE: "update",
  CREATE: "create",
};

export const OVERLAY_TYPE = {
  API_SAVING: "api_saving",
  API_LOADING: "api_loading",
};

export const LEVEL_DEFINITIONS = {
  getForLevel(level = 0) {
    switch (level) {
      case 0:
        return "Entry";
      case 1:
        return "Medior";
      case 2:
        return "Senior";
      case 3:
        return "Executive";
      default:
        return "Executive";
    }
  },
};

export const CAREER_DIRECTIONS = {
  FRONTEND: "frontend",
  BACKEND: "backend",
  GENERALIST: "generalist",
};
