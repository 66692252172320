import React from "react";
import { Col, Row } from "react-bootstrap";

export const ScreenHeader = ({ title }) => (
  <Row className="justify-content-center">
    <Col xs="auto">
      <h1>{title}</h1>
    </Col>
  </Row>
);
