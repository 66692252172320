import React, { useLayoutEffect } from "react";
import { Container } from "react-bootstrap";
import _ from "lodash";
import "./path.css";
import { API } from "../../api";
import { setPathList, toggleEdit, usePaths } from "../../redux/paths";
import { useUser } from "../../redux/user";
import { NewLevel } from "./components/NewLevel";
import { EditModal } from "./components/EditModal";
import { CreateModal } from "./components/CreateModal";
import { SaveLoading } from "../common/SaveLoading";
import { ScreenHeader } from "../common/ScreenHeader";
import { Level } from "./components/Level/Level";
import { SpinLoading } from "../common/SpinLoading";
import { toggleOverlay } from "../../redux/overlay";
import { OVERLAY_TYPE } from "../../enums";

class Toggler {
  setUser(user) {
    this.user = user;
  }
  toggleIfUserAdmin() {
    if (this.user && this.user.role && this.user.role === "admin") toggleEdit();
  }
}
const toggler = new Toggler();

document.addEventListener("keydown", (e) =>
  e.ctrlKey ? toggler.toggleIfUserAdmin() : null
);

export const Paths = () => {
  const paths = usePaths();
  const { user, token } = useUser();
  if (user) {
    toggler.setUser(user);
  }
  useLayoutEffect(() => {
    // TODO: Fix this hack here as if we would wipe the available database this would
    // be stuck in a forever loop
    if (paths.loaded && paths.available.length > 0) return;
    toggleOverlay(OVERLAY_TYPE.API_LOADING);
    API.fetchPath(token).then((paths) => {
      setPathList(paths);
      toggleOverlay();
    });
  });
  return (
    <>
      <SaveLoading />
      <EditModal />
      <CreateModal />
      <Container fluid>
        <ScreenHeader title="Career path" />
        <SpinLoading />
        {Object.keys(paths.levelGrouped).map((levelKey) => (
          <Level levelKey={levelKey} key={levelKey} />
        ))}
        {paths.edit.showOption ? <NewLevel /> : null}
      </Container>
    </>
  );
};
