import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { addNewLevel } from "../../../redux/paths";

export const NewLevel = () => {
  const onClick = (e) => {
    e.preventDefault();
    addNewLevel();
  }
  return (
    <Container fluid className="mt-5 mb-5" onClick={onClick}>
      <Row className="justify-content-center path__level path__level_new">
        <Col xs="auto">
          <h2>Add new level</h2>
        </Col>
      </Row>
    </Container>
  );
};
