import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { LEVEL_DEFINITIONS } from "../../../../enums";
import { getHUFDisplay } from "../../../../pricing/price";
import { PathDirection } from "./components/PathDirection";
import "./pathModal.css";

export const PathModal = ({
  _id,
  title,
  categories,
  level,
  priceRange = {},
  directions = [],
  onSave,
  onClose,
}) => {
  const [innerTitle, setInnerTitle] = useState(title);
  const [innerCategories, setInnerCategories] = useState(categories);
  const [innerPriceRange, setInnerPriceRange] = useState(priceRange);
  const [innerDirections, setInnerDirections] = useState(directions);
  const [setMinimumPrice, setMaximumPrice] = [
    (min) => setInnerPriceRange({ ...innerPriceRange, min }),
    (max) => setInnerPriceRange({ ...innerPriceRange, max }),
  ];
  const setCategoryName = (from, to) =>
    setInnerCategories(
      innerCategories.map((iCategory) => {
        if (iCategory.name === from) return { ...iCategory, name: to };
        return iCategory;
      })
    );
  const addNewEmptyCategory = () =>
    setInnerCategories([
      ...innerCategories,
      { name: "New category", subdimensions: [] },
    ]);
  const toggleDirection = (direction) => {
    console.log('toggleDirection ', direction, innerDirections);
    const index = innerDirections.findIndex((v) => v === direction);
    if (index < 0) return setInnerDirections([...innerDirections, direction]);
    setInnerDirections(innerDirections.filter((_, i) => i !== index));
  };
  const addNewSubdimensionForIndex = (i, dim) =>
    setInnerCategories(
      innerCategories.map((iCategory, y) => {
        if (y === i) {
          iCategory.subdimensions.push(dim);
        }
        return iCategory;
      })
    );
  const setDimensionNameForIndex = (i, from, to) =>
    setInnerCategories(
      innerCategories.map((iCategory, y) => {
        if (y === i) {
          const subIndex = iCategory.subdimensions.findIndex((v) => v === from);
          iCategory.subdimensions[subIndex] = to;
        }
        return iCategory;
      })
    );
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit role</Modal.Title>
        <span className={`path__modal_level_title path__modal_level_${level}`}>
          {LEVEL_DEFINITIONS.getForLevel(level)}
        </span>
      </Modal.Header>
      <Modal.Body>
        <PathDirection
          directions={innerDirections}
          toggleDirection={toggleDirection}
        />
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="name-addon">Name</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Name"
            aria-describedby="name-addon"
            value={innerTitle}
            onChange={(e) => setInnerTitle(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="min-price-addon">
              Minimum (Gross) - {getHUFDisplay(innerPriceRange.min)}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Minimum price(HUF Gross)"
            aria-describedby="min-price-addon"
            value={
              Number.isInteger(innerPriceRange.min) ? innerPriceRange.min : 0
            }
            onChange={(e) => setMinimumPrice(Number.parseInt(e.target.value))}
          />
        </InputGroup>
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="max-price-addon">
              Maximum (Gross) - {getHUFDisplay(innerPriceRange.max)}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Maximum price(HUF Gross)"
            aria-describedby="max-price-addon"
            value={
              Number.isInteger(innerPriceRange.max) ? innerPriceRange.max : 0
            }
            onChange={(e) => setMaximumPrice(Number.parseInt(e.target.value))}
          />
        </InputGroup>
        {innerCategories.map((category, i) => {
          const { name, subdimensions } = category;
          return (
            <Container fluid className="edit_modal__category mb-3" key={i}>
              <InputGroup key={i} className="mb-3 mt-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="category-name-addon">
                    Category name
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Category name"
                  aria-describedby="category-name-addon"
                  value={name}
                  onChange={(e) => setCategoryName(name, e.target.value)}
                />
              </InputGroup>
              {subdimensions.map((dim, y) => {
                return (
                  <InputGroup key={y} className="mb-2 mt-2">
                    <FormControl
                      value={dim}
                      onChange={(e) =>
                        setDimensionNameForIndex(i, dim, e.target.value)
                      }
                      as="textarea"
                    />
                  </InputGroup>
                );
              })}
              <Row className="justify-content-center mb-2 mt-2">
                <Col xs="auto">
                  <Button
                    variant="secondary"
                    onClick={() => addNewSubdimensionForIndex(i, "New subdim")}
                  >
                    Add new subdimension
                  </Button>
                </Col>
              </Row>
            </Container>
          );
        })}
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="secondary" onClick={addNewEmptyCategory}>
              Add new category
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            onSave(_id, innerTitle, level, innerCategories, innerPriceRange, innerDirections)
          }
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </>
  );
};
