import React, { useLayoutEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { API } from "../../api";
import { OVERLAY_TYPE } from "../../enums";
import { toggleOverlay } from "../../redux/overlay";
import { setPathList, usePaths } from "../../redux/paths";
import { useUser } from "../../redux/user";
import { setUsersList, useUsers } from "../../redux/users";
import { ScreenHeader } from "../common/ScreenHeader";
import { SpinLoading } from "../common/SpinLoading";
import { UserRow } from "./components/UserRow/UserRow";

export const Users = () => {
  const { token } = useUser();
  const { list: usersList, loaded: usersLoaded, display } = useUsers();
  const { loaded: pathsLoaded } = usePaths();
  useLayoutEffect(() => {
    if (usersLoaded && pathsLoaded) return;
    toggleOverlay(OVERLAY_TYPE.API_LOADING);
    let userPromise = Promise.resolve();
    if (!usersLoaded) {
      userPromise = API.fetchUsers(token).then(({ users }) => {
        setUsersList(users);
      });
    }
    let pathsPromise = Promise.resolve();
    if (!pathsLoaded) {
      pathsPromise = API.fetchPath(token).then(paths => {
        setPathList(paths);
      });
    }
    Promise.all([userPromise, pathsPromise]).then(toggleOverlay);
  });
  const openedAheadSorting = (a, b) =>
    display.usersShown.findIndex((id) => id === b.user._id) -
    display.usersShown.findIndex((id) => id === a.user._id);
  return (
    <Container fluid>
      <ScreenHeader title="User list" />
      <SpinLoading />
      <Row>
        {usersList.sort(openedAheadSorting).map(({ user, evaluation }, i) => (
          <UserRow key={i} user={user} evaluation={evaluation} />
        ))}
      </Row>
    </Container>
  );
};
