import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./smartCollapsingRow.css";

export const SmartCollapsingRow = ({ children, ...rest }) => {
  const [opened, setOpened] = useState(0);
  const openSize = 12 - children.length;
  return (
    <Row {...rest}>
      {children.map((child, i) => (
        <Col
          className={`smart ${i === opened ? "" : "collapsed_col"}`}
          xs={`${i === opened ? openSize : "1"}`}
          key={i}
          onClick={() => (opened === i ? null : setOpened(i))}
        >
          {{ ...child, props: { ...child.props, opened: opened === i } }}
        </Col>
      ))}
    </Row>
  );
};
