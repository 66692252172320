import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { API } from "../../../../api";
import { OVERLAY_TYPE } from "../../../../enums";
import {
  setCandidateEvaluation,
  useCandidate,
} from "../../../../redux/candidate";
import { toggleOverlay } from "../../../../redux/overlay";
import { useUser } from "../../../../redux/user";
import { SpinButtonLoading } from "../../../common/SpinButtonLoading";

export const EvaluateButtonRow = () => {
  const { token } = useUser();
  const {
    completed,
    evaluation,
    information,
    direction,
    rejected,
  } = useCandidate();
  const sendEvaluation = () => {
    if (completed.length === 0) return;
    toggleOverlay(OVERLAY_TYPE.API_SAVING);
    API.getEvaluation(token, completed)
      .then((evaluation) => setCandidateEvaluation(evaluation))
      .catch(console.log)
      .finally(() => toggleOverlay());
  };
  const saveEvaluation = () => {
    if (evaluation.length === 0) return;
    toggleOverlay(OVERLAY_TYPE.API_SAVING);
    API.saveEvaluation(token, {
      information,
      completed,
      direction,
      rejected,
      evaluation,
    })
      .catch(console.log)
      .finally(() => toggleOverlay());
  };
  return (
    <Container fluid>
      <Row>
        <Col xs="auto">
          <SpinButtonLoading
            className="primary"
            onClick={() => sendEvaluation()}
            text="Evaluate"
          ></SpinButtonLoading>
        </Col>
        <Col xs="auto" hidden={evaluation.length === 0}>
          <SpinButtonLoading
            className="secondary"
            onClick={() => saveEvaluation()}
            text="Save evaluation"
          ></SpinButtonLoading>
        </Col>
      </Row>
    </Container>
  );
};
