import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

export const Box = ({ name, icon, ...rest}) => {
  return (
    <Container {...rest} fluid className="home__box_col_container pt-2">
      <Row className="justify-content-center mt-1">
        <Col xs="auto">
          <h3>{name}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Image src={icon} width="100%" />
        </Col>
      </Row>
    </Container>
  )
}
