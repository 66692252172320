import React from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";

export const Category = ({
  _id,
  name,
  subdimensions,
  isCompleted,
  complete,
  ...rest
}) => {
  return (
    <Container {...rest} fluid>
      <Row>
        <Col xs="auto">
          <h4 className="path__title">{name}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {subdimensions.map((dim, y) => {
              const isCompletedSubDim = isCompleted(_id, dim);
              return (
                <ListGroup.Item
                  key={y}
                  active={isCompletedSubDim}
                  className={`path__list_group_item ${
                    isCompletedSubDim ? null : "to_be_completed"
                  }`}
                  onClick={() =>
                    isCompletedSubDim ? null : complete(_id, name, dim)
                  }
                >
                  {dim}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
