import { useState, useLayoutEffect } from "react";
import { store } from "./store";

const storeKey = "evaluations";
const initialState = {
  list: [],
  loaded: false,
};

const reducers = {
  setEvaluationsList: (state, { list = state.list, loaded = state.loaded }) => ({
    ...state,
    list,
    loaded,
  }),
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const useEvaluations = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const setEvaluationsList = (list, loaded = true) =>
  store.dispatch({ type: "setEvaluationsList", payload: { list, loaded } });

// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
