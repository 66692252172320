import { useState, useLayoutEffect } from "react";
import { store } from "./store";

const storeKey = "users";

// DEFINITIONS
const initialState = {
  list: [],
  display: {
    usersShown: [],
  },
  loaded: false,
};

const reducers = {
  setUsers: (state, { users: list }) => {
    return { ...state, list, loaded: true };
  },
  updateUserInList: (state, { user }) => {
    const foundIndex = state.list.findIndex(({ _id }) => _id === user._id);
    if (!foundIndex) return state;
    return {
      ...state,
      list: state.list.map((item, i) => (i === foundIndex ? user : item)),
    };
  },
  toggleUserOpen: (state, { _id }) => {
    const foundIndex = state.display.usersShown.findIndex((id) => id === _id);
    if (foundIndex < 0)
      return {
        ...state,
        display: {
          ...state.display,
          usersShown: [...state.display.usersShown, _id],
        },
      };
    return {
      ...state,
      display: {
        ...state.display,
        usersShown: state.display.usersShown.filter((id) => id !== _id),
      },
    };
  },
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const useUsers = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const setUsersList = (users) =>
  store.dispatch({ type: "setUsers", payload: { users } });
export const toggleUserOpen = (id) =>
  store.dispatch({ type: "toggleUserOpen", payload: { _id: id } });
export const updateUserInList = (user) =>
  store.dispatch({ type: "updateUserInList", payload: { user } });
// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
