import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { OVERLAY_TYPE } from "../../enums";
import { toggleOverlay, useOverlay } from "../../redux/overlay";
import "./saveLoading.css";

export const SaveLoading = () => {
  const { active, type } = useOverlay();
  return (
    <Modal
      show={active && type === OVERLAY_TYPE.API_SAVING}
      onHide={toggleOverlay}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Saving in progress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col xs="auto">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin save_loading__icon"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
