import { useState, useLayoutEffect } from "react";
import { OVERLAY_TYPE } from "../enums";
import { store } from "./store";

const storeKey = "overlay";
const initialState = {
  active: false,
  type: OVERLAY_TYPE.API_LOADING,
};

const reducers = {
  toggleOverlay: (state, { type = state.type }) => ({
    ...state,
    active: !state.active,
    type,
  }),
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const useOverlay = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const toggleOverlay = (type) =>
  store.dispatch({ type: "toggleOverlay", payload: { type } });

// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
