import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { LEVEL_DEFINITIONS } from "../../../../enums";
import { toggleOpenLevel, usePaths } from "../../../../redux/paths";

export const CollapsedLevel = ({ level }) => {
  const { display } = usePaths();
  const collapsed = display.levels.opened.findIndex(l => level === l) < 0;
  return (
    <Row
      className={`path__level_collapsed path__level_${level} justify-content-between`}
      hidden={!collapsed}
      onClick={() => toggleOpenLevel(level)}
    >
      <Col xs="auto">
        <span className="path__level_title">
          {LEVEL_DEFINITIONS.getForLevel(level)}
        </span>
      </Col>
      <Col xs="auto" style={{ fontSize: "2em" }}>
        <FontAwesomeIcon icon={faChevronDown} />
      </Col>
    </Row>
  );
};
