import React from "react";
import { Col, Row } from "react-bootstrap";
import { usePaths } from "../../../../../redux/paths";
import { getHUFDisplay } from "../../../../../pricing/price";

export const PriceRange = ({ level, index }) => {
  const { levelGrouped } = usePaths();
  const { priceRange: range } = levelGrouped[level][index];
  return (
    <>
      <Row
        className="justify-content-end"
        hidden={!!!range.min || !Number.isInteger(range.min)}
      >
        <Col xs="auto" className="path__price">
          {getHUFDisplay(range.min)}
        </Col>
      </Row>
      <Row
        className="justify-content-end"
        hidden={!!!range.max || !Number.isInteger(range.max)}
      >
        <Col xs="auto" className="path__price">
          {getHUFDisplay(range.max)}
        </Col>
      </Row>
    </>
  );
};
