import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { getNetFromGross } from "../../../../../../pricing/price";
import { AchievementsDisplay } from "./components/AchievementsDisplay/AchievementsDisplay";
import { EvaluationDisplay } from "./components/EvaluationDisplay";
import "./roleEvaluation.css";

export const RoleEvaluation = ({
  _id,
  userId,
  grossPayment = 0,
  title = "",
  completedPercentage = 0,
  ...rest
}) => {
  const DISPLAY_MODES = {
    EVALUATION: "evaluation",
    ACHIEVEMENTS: "achievements",
  };
  const [display, setDisplay] = useState(DISPLAY_MODES.EVALUATION);
  const paymentInHand = getNetFromGross(grossPayment)
  const switchDisplay = (from) => {
    switch (from) {
      case DISPLAY_MODES.EVALUATION:
        return setDisplay(DISPLAY_MODES.ACHIEVEMENTS);
      default:
        return setDisplay(DISPLAY_MODES.EVALUATION);
    }
  };
  return (
    <Col xs="3" {...rest}>
      <Card style={{ width: "100%", height: "100%" }}>
        <Card.Title className="role_evaluation__card_title">{title}</Card.Title>
        <Card.Body className="role_evaluation__card_body">
          <Row
            className="justify-content-center mb-2 role_evaluation__card_display_switch"
            onClick={() => switchDisplay(display)}
          >
            <Col xs="auto">
              <span>Switch display mode</span>
            </Col>
          </Row>
          <EvaluationDisplay
            hidden={display !== DISPLAY_MODES.EVALUATION}
            {...{ paymentInHand, grossPayment, completedPercentage }}
          />
          <AchievementsDisplay
            hidden={display !== DISPLAY_MODES.ACHIEVEMENTS}
            {...{ _id, userId }}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};
