import React from "react";
import { Col, Row } from "react-bootstrap";
import { toggleCreate } from "../../../../redux/paths";
import "./newRole.css";

export const NewRole = ({ level }) => {
  return (
    <Col xs="auto" className="new_role__col" onClick={() => toggleCreate(level)}>
      <Row className="justify-content-center pt-3 pb-3">
        <Col xs="auto">
          <h4>Add new role to level {level}</h4>
        </Col>
      </Row>
    </Col>
  );
};
