import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Routing } from "./router";
import { useUser } from "./redux/user";

class App extends Component {
  render() {
    return (
      <Container fluid>
        <Routing />
      </Container>
    );
  }
}
export default App;
