import { useState, useLayoutEffect } from "react";
import { store } from "./store";

const storeKey = "user";

// DEFINITIONS
const initialState = {
  token: null,
  user: null,
};

const reducers = {
  login: (state, { user, token }) => {
    if (!user || !token) {
      return state;
    }
    return { user, token };
  },
  logout: () => ({ ...initialState }),
};

// HELPERS
const getState = () => store.getState()[storeKey];

const subscribe = (f) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

// EXPORTS
export const useUser = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};

export const login = (item) => store.dispatch({ type: "login", payload: item });

export const logout = () =>
  store.dispatch({
    type: "logout",
  });

// INJECT-REDUCERS INTO REDUX STORE
store.injectReducer(storeKey, (state = initialState, { type, payload }) =>
  reducers[type] ? reducers[type](state, payload) : state
);
