import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import { login } from "../../redux/user";
import { API } from "../../api";
import { setPathList } from "../../redux/paths";
import { ScreenHeader } from "../common/ScreenHeader";
import "./login.css";

export const Login = () => {
  const authenticate = async (token) => {
    try {
      let response = await API.login(token);
      const internalToken = response.data.token;
      response = await API.me(internalToken);
      localStorage.setItem("token", internalToken);
      return { user: response.data, token: internalToken };
    } catch (e) {
      console.log("authenticate ", e);
      return {};
    }
  };

  const responseGoogle = async (response) => {
    const auth = await authenticate(response.tokenId);
    login(auth);
    setPathList({ completed: auth.user.completed });
  };
  return (
    <Container fluid>
      <ScreenHeader title="Welcome to Riltech" />
      <Row className="justify-content-center mt-3">
        <Col xs="4">
          <GoogleLogin
            className="w-100"
            clientId="293795868345-rloi5b84s422l4rcer6vp0nfgab505ca.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col xs="auto">
          <p className="login__not_a_member"> 
            Not a member of our company?{" "}
            <a href="https://riltech.co">Visit our official page</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};
