import React, { useEffect, useState } from "react";
import { Container, InputGroup, FormControl } from "react-bootstrap";
import useDebounce from "../../../debouncer/useDebounce";
import {
  setCandidateInformation,
  useCandidate,
} from "../../../redux/candidate";

export const CandidateInformation = () => {
  const { information } = useCandidate();
  const [name, setName] = useState(information.name);
  const [email, setEmail] = useState(information.email);
  const [debouncedName, debouncedEmail] = [
    useDebounce(name, 100),
    useDebounce(email, 100),
  ];
  useEffect(() => {
    const nameChanged = debouncedName && debouncedName !== information.name;
    const emailChanged = debouncedEmail && debouncedEmail !== information.email;
    if (nameChanged || emailChanged) {
      setCandidateInformation(debouncedName, debouncedEmail);
    }
  });
  return (
    <Container>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Prepend>
          <InputGroup.Text id="candidate-name-addon">Name</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          aria-label="Candidate name"
          aria-describedby="candidate-name-addon"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Prepend>
          <InputGroup.Text id="candidate-email-addon">Email</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          aria-label="Candidate email"
          aria-describedby="candidate-email-addon"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputGroup>
    </Container>
  );
};
