export class MockAPI {
  constructor() {}
  fetchPath() {
    return {
      available: [
        {
          _id: "1b2a8769-b792-4c99-a6b3-9f32436389ac",
          level: 0,
          title: "Junior developer",
          categories: [
            {
              name: "Engineering",
              subdimensions: [
                "Can work with at least 1 technology on either backend or frontend side",
                "Able to use basic git commands - commit, push, pull, checkout, branch",
                "Understands gitflow",
                "Commit messages are understandable and clear",
                "Understands basic datastructures",
              ],
            },
          ],
        },
        {
          _id: "d5b7848d-d8b3-41a2-932a-f249ffcf20f4",
          level: 1,
          title: "Medior Backend Developer",
          categories: [
            {
              name: "Engineering",
              subdimensions: [
                "Can work with at least 1 technology on either backend or frontend side",
                "Able to use basic git commands - commit, push, pull, checkout, branch",
                "Understands gitflow",
                "Commit messages are understandable and clear",
                "Understands basic datastructures",
              ],
            },
          ],
        },
        {
          _id: "bab68c9b-4474-4cb6-80ac-fd37577d6db3",
          level: 1,
          title: "Medior Frontend Developer",
          categories: [
            {
              name: "Engineering",
              subdimensions: [
                "Can work with at least 1 technology on either backend or frontend side",
                "Able to use basic git commands - commit, push, pull, checkout, branch",
                "Understands gitflow",
                "Commit messages are understandable and clear",
                "Understands basic datastructures",
              ],
            },
          ],
        },
      ],
      completed: [
        {
          _id: "d5b7848d-d8b3-41a2-932a-f249ffcf20f4",
          level: 0,
          title: "Junior developer",
          categories: [
            {
              name: "Engineering",
              subdimensions: [
                "Can work with at least 1 technology on either backend or frontend side",
                "Understands gitflow",
              ],
            },
          ],
        },
        {
          _id: "1b2a8769-b792-4c99-a6b3-9f32436389ac",
          level: 1,
          title: "Medior Frontend Developer",
          categories: [
            {
              name: "Engineering",
              subdimensions: [
                "Can work with at least 1 technology on either backend or frontend side",
                "Understands gitflow",
              ],
            },
          ],
        },
      ],
    };
  }
}
