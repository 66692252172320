import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { OVERLAY_TYPE } from "../../enums";
import { toggleOverlay, useOverlay } from "../../redux/overlay";
import "./saveLoading.css";

export const SpinLoading = () => {
  const { active, type } = useOverlay();
  return (
    <Container fluid>
      <Row
        className="justify-content-center"
        hidden={!active || type !== OVERLAY_TYPE.API_LOADING}
      >
        <Col xs="auto">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin save_loading__icon"
          />
        </Col>
      </Row>
    </Container>
  );
};
