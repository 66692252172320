import React from "react";
import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { logout, useUser } from "../../redux/user";
import logo from "../../assets/RLT_logo.png";
import "./navigation.css";
import { useHistory } from "react-router-dom";

export const Navigation = () => {
  const { user } = useUser();
  const history = useHistory();
  const signout = () => {
    localStorage.removeItem("token");
    logout();
  };
  const attachUserBasedNavigation = () => {
    if (!user) return null;
    const getAdminMenu = () =>
      user.role === "admin" ? (
        <NavDropdown title="Admin options" id="basic-nav-dropdown">
          <NavDropdown.Item onClick={() => history.push("/users")}>
            User list
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => history.push("/candidate")}>
            Candidate
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => history.push("/evaluations")}>
            Evaluations
          </NavDropdown.Item>
        </NavDropdown>
      ) : null;
    return (
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
        <Nav className="mr-auto">{getAdminMenu()}</Nav>
        <Navbar.Text className="nav__logout" onClick={signout}>
          Logout
        </Navbar.Text>
      </Navbar.Collapse>
    );
  };
  return (
    <Navbar expand="lg" className="ril__navbar">
      <Navbar.Brand
        onClick={() => history.push("/")}
        className="ril__navbar_brand"
      >
        <Image src={logo} className="ril-logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {attachUserBasedNavigation()}
    </Navbar>
  );
};
